import type { PageRequest, PageResponse, Response } from '~/type'
import type { MyCustomerRecordDTO, MyFeedbackRecordDTO, TypeRes } from '~/type/messageCenter'
import { http } from '~/utils/http'

// 查询玩家客服列表
export const searchMyCustomerList = async (
  data: PageRequest<{
    // 客服类型 1 财务客服 2 普通客服 3浮窗客服
    customerType: number | undefined
  }> = {}
) => {
  return await http<PageResponse<MyCustomerRecordDTO>>('/app-api/member/customer/service/list', {
    method: 'post',
    body: data
  }).then((res) => {
    return res.data?.records || []
  })
}

// 查询玩家问题列表
export const searchFeedbackList = async (data: PageRequest) => {
  return await http<PageResponse<MyFeedbackRecordDTO>>('/app-api/member/feedback/list', {
    method: 'post',
    body: data
  })
}

// 玩家问题反馈问题类型反馈
export const feedbackType = async () => {
  return await http<Response<TypeRes[]>>('/app-api/member/feedback/type', {
    method: 'post'
  })
}

// 玩家问题反馈详情
export const feedbackDetail = async (data: { id?: number }) => {
  return await http<Response<MyFeedbackRecordDTO>>('/app-api/member/feedback/detail', {
    method: 'post',
    body: data
  })
}

// 删除玩家问题反馈
export const feedbackDelete = async (data: { id?: number }) => {
  return await http<Response<boolean>>('/app-api/member/feedback/delete', {
    method: 'post',
    body: data
  })
}

// 添加玩家问题反馈
export const feedbackAdd = async (data: { questionType?: number; content?: string; fileUrl?: string[] }) => {
  return await http<Response<boolean>>('/app-api/member/feedback/add', {
    method: 'post',
    body: data
  })
}

// 上传文件
export const uploadFile = async (fd: FormData, onProgress: (_number: number) => void) => {
  return await http<Response<string>>('/app-api/infra/file/upload', {
    method: 'post',
    body: fd,
    onProgress
  })
}
